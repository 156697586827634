<template>
  <v-layout>
    <app-loader
      title="Загрузка данных Сообщений"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      class="no-print"
      column
      align-center
    >
      <v-flex>
        <v-card
          tile
          class="mt-0 mb-1 ml-0 pa-2 elevation-1"
          :style="{ 'minWidth': '800px',
                    'overflow-x': 'hidden', }"
        >
          <v-layout align-center>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn
                  slot="activator"
                  small
                  icon
                  class="ma-0"
                  @click="$router.go(-1)"
                  v-on="on"
                >
                  <v-icon color="grey darken-1">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Назад</span>
            </v-tooltip>
            <h2 class="px-2 title">
              Сообщения
            </h2>
          </v-layout>
          <v-layout>
            <span class="primary--text">Поставка №
              <span class="primary--text text-decoration-underline"> {{ number }}</span> </span>
          </v-layout>
        </v-card>
        <v-card
          tile
          class="mt-0 mb-1 ml-0 pa-2 elevation-1"
          :style="{ 'minWidth': '800px' }"
        >
          <div
            class="pa-1 worked-variant-table"
            :style="{height: blockHeight + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'}"
          >
            <v-list>
              <v-list-item
                v-for="item in filteredItems"
                :key="item.id"
                :class="['compact', !item.isReaded ? 'blue lighten-5' : '']"
              >
                <v-list-item-content
                  :class="{
                    'blue--text': item.isReaded
                  }"
                >
                  <div>
                    <span style="color: rgba(0,0,0,.54);">{{ item.author }}: {{ item.createdAt | dateTime }} </span><span> {{ item.text }}</span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import * as shippingsApi from "../../../backend/shippingsApi.js";

export default {
  props: ["id", "number"],
  data() {
    return {
      items: [],
      loading: false,
      dataRestored: false,
    };
  },
  computed: {
    blockHeight() {
      return window.innerHeight - 250;
    },
    pageLoaded() {
      return !!this.dataRestored;
    },
    filteredItems() {
      return this.items.sort((a, b) => a.createdAt - b.createdAt);
    },
    newMessages() {
      return this.items.filter(i => !i.isReaded);
    },
  },
  created() {
    this.getMessages();
    setTimeout(() => {
      this.markAllAsRead();
    }, 9000);
  },
  methods: {
    async getMessages() {
      this.loading = true;
      const res = await shippingsApi.getMessages(this.id);
      this.items = res;
      this.items.forEach(item => {
        const author = item.author.replace(/[^a-zа-яё0-9:,\s]/gi, " ").trim().split(",");
        item.author = author[1].substring(author[1].indexOf(": ") + 2, author[1].length);
      });
      this.loading = false;
      this.dataRestored = true;
    },
    async markAsRead(itemId) {
      await shippingsApi.markAsReadMessage(this.id, itemId);
    },
    async markAllAsRead() {
      for (let i=0; i < this.newMessages.length; i++) {
        const index = this.newMessages[i];
        await this.markAsRead(index.id);
      }
      this.getMessages();
    },
  },
};
</script>
